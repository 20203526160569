.PublishedDetails {

  padding: $base-vertical-spacing--large $base-mobile-gutter 0;

  @include mq($from: desktop) {
    padding: $base-vertical-spacing $base-mobile-gutter $base-vertical-spacing--xlarge;
  }

  &-inner {

    @extend %maxWidth;
  }

  &-published {
    font-style: italic;

    @include mq($from: desktop) {
      margin-bottom: $base-vertical-spacing--medium;
    }
  }

  &-date {
    margin-bottom: $base-mobile-gutter;

    @include mq($from: desktop) {
      margin-bottom: $base-vertical-spacing--large;
    }
  }

  &-name {
    margin-bottom: $base-mobile-gutter;

    @include mq($from: desktop) {
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: $base-spacing;
    }
  }

  &-image {
    border-radius: 50%;
    height: 85px;
    margin: $base-vertical-spacing--medium auto;
    width: 85px;

    @include mq($from: desktop) {
      margin: 0 $base-vertical-spacing--medium 0 0;
    }
  }

  &-description {
    margin-bottom: 0;
  }

  &-container {

    @include mq($from: desktop) {
      display: flex;
    }
  }

  &-descriptionContainer {

    @include mq($from: desktop) {
      margin: 0;
      max-width: 550px;
    }
  }
}
