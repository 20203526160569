.PrimaryHero {
  background: url("../img/HomeHero.jpg") 42% 35% / cover no-repeat;
  color: $white;
  padding: 90px $base-mobile-gutter $base-mobile-gutter;

  @include mq($from: desktop) {
    background-size: cover;
    display: flex;
    height: 0;
    justify-content: center;
    margin-bottom: -5px;
    padding-top: 48.44%;
    position: relative;
    z-index: 1;
  }

  &-headline {
    font-size: 27px;
    margin-bottom: $base-vertical-spacing--large;
    text-align: center;

    @include mq($from: desktop) {
      font-size: 3.6rem;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      text-align: left;
      top: 40%;
      width: 825px;
    }
  }

  &-scrollHint {
    display: none;
    font-size: 1.6rem;
    text-align: center;

    @include mq($from: desktop) {
      bottom: 20px;
      display: block;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
    }

    a {
      color: $white;
      display: block;
      margin: auto;
      width: 150px;
    }

    img {
      display: block;
      left: 0;
      margin: auto;
      margin-bottom: 13px;
      right: 0;
      width: 45px;
    }
  }
}

.PrimaryHero.session {
  background: url("../img/LandingHero.jpg") 58% 35% / cover no-repeat;

  @include mq($from: desktop) {
    background-size: cover;
    padding-top: 27.2%;
  }
}

.PrimaryHero.about {
  background: url("../img/ContentHero.jpg") 50% 35% / cover no-repeat;

  @include mq($from: desktop) {
    background-size: cover;
    padding-top: 27.2%;
  }
}

#below {
  bottom: 60px;
  position: absolute;
}
