.WhosItFor {

  @extend %baseComponent;

  background: $white;

  &-inner {

    @extend %maxWidthNarrow;
  }

  @include mq($from: desktop) {
    background: $white url("../img/RainingPotato.svg") 73% 50%/22% no-repeat;
    padding: $base-vertical-spacing--large 0 $base-vertical-spacing--xlarge;
  }

  h6 span,
  p span {
    font-weight: bold;
  }

  &-listHeader,
  &-list {
    margin-bottom: $base-vertical-spacing--medium;
  }

  &-list,
  ul {
    padding: 0 25px;

    @include mq($from: desktop) {
      padding: 0 0 0 40px;
    }

    li {
      margin-bottom: $base-vertical-spacing;
    }

    li {
      position: relative;

      &::before {
        content: url("../img/BulletArrowGreen.svg");
        height: 30px;
        left: -30px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
      }
    }
  }

  p:first-of-type {
    margin-bottom: $base-vertical-spacing;
  }

}
