.ContributorCard {

  @extend %baseComponent;

  padding-top: $base-vertical-spacing;

  @include mq($from: desktop) {
    background: url("../img/BGHeart.svg") 12% 5%/155px no-repeat;
    padding-top: 0;
  }

  &-inner {

    @extend %maxWidthNarrow;
  }

  &.is-overlapped {
    padding-bottom: 33vw;

    @include mq($from: desktop) {
      padding-bottom: 180px;
    }
  }

  &-header {

    @include mq($from: desktop) {
      margin-bottom: $base-vertical-spacing--large;
    }
  }

  &-container {

    @include mq($from: desktop) {
      display: flex;
      margin-top: $base-vertical-spacing--large;

      div {
        margin: 0;
      }

      &.right-sided {
        flex-direction: row-reverse;

        .ContributorCard-img {
          margin: 0 0 0 $base-vertical-spacing;
        }
      }
    }
  }

  &-p {
    font-size: 1.4rem;
    margin-bottom: $base-spacing;

    span {
      font-weight: bold;
    }

    @include mq($from: desktop) {
      font-size: 1.6rem;
    }
  }

  &-img {
    align-self: baseline;
    margin: $base-vertical-spacing--medium 0 $base-vertical-spacing;
    width: 100px;

    @include mq($from: desktop) {
      margin: 0 $base-vertical-spacing 0 0;
      width: 28%;
    }
  }

  &-subHeader {
    font-size: 2.2rem;

    @include mq($from: desktop) {
      font-size: 3rem;
      margin-bottom: $base-vertical-spacing--medium;
    }
  }

  &-h6 {
    font-weight: bold;
    margin-bottom: $base-vertical-spacing--small;

    &.margin-top {
      margin-top: $base-vertical-spacing--small;
    }

    @include mq($from: desktop) {
      font-size: 1.7rem;
      margin-bottom: $base-vertical-spacing;
    }
  }
}
