.Spotlights {

  @extend %baseComponent;
}

.Spotlight {

  @extend %maxWidth;
  margin-bottom: $base-vertical-spacing--large;
  margin-top: $base-vertical-spacing--large;

  @include mq(tablet) {
    margin-bottom: $base-vertical-spacing--xlarge;
    margin-top: $base-vertical-spacing--xlarge;
  }

  > a {
    display: flex;

    @include mq(tablet) {
      align-items: center;
    }
  }
}

.Spotlight-text {

  > h2 {
    margin-bottom: $base-vertical-spacing--small;

    @include mq(tablet) {
      margin-bottom: $base-vertical-spacing;
    }
  }

  > p {

    @include mq(tablet) {
      font-size: 1.9rem;
      line-height: 3.6rem;
    }
  }
}

.Spotlight--img-right {

  > a {
    flex-direction: column-reverse;

    @include mq(tablet) {
      flex-direction: row;
    }
  }

  .Spotlight-img {
    margin-bottom: $base-vertical-spacing--small;

    @include mq(tablet) {
      margin: 0 0 0 40px;
    }
  }
}

.Spotlight--img-left {

  > a {
    flex-direction: column;

    @include mq(tablet) {
      flex-direction: row;
    }
  }

  .Spotlight-img {
    margin-bottom: $base-vertical-spacing--small;

    @include mq(tablet) {
      margin: 0 40px 0 0;
    }
  }
}
