.Quote {

  @extend %baseComponent;

  position: relative;

  @include mq($from: desktop) {
    padding-bottom: $base-vertical-spacing--xlarge;
  }

  &-inner {

    @extend %maxWidth;
  }

  &.is-overlapped {
    padding-bottom: 33vw;

    @include mq($from: desktop) {
      padding-bottom: 180px;
    }
  }

  &.afterVideo {
    padding-top: 0;
  }

  &-text {
    font-size: 2.4rem;
    margin-bottom: $base-vertical-spacing--medium;

    @include mq($from: desktop) {
      font-size: 4rem;
      line-height: 5rem;
      margin-bottom: $base-vertical-spacing--large;
      padding-top: $base-vertical-spacing;
    }
  }

  &-citation {
    color: $lima;
    font-style: normal;
  }

  hr {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: calc(100% - 34px);
  }
}
