// Hide only visually, but have it available for screen readers:
// http://snook.ca/archives/html_and_css/hiding-content-for-accessibility

.is-visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Hide visually and from screen readers

.is-hidden {
  display: none !important;
}

// Sass placeholders

%maxWidth {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;

  @include mq($from: desktop) {
    max-width: $max-inner-width;
  }
}

%maxWidthNarrow {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;

  @include mq($from: desktop) {
    max-width: $max-inner-width--narrow;
  }
}

%baseComponent {
  padding: $base-vertical-spacing--large $base-mobile-gutter;
  text-align: center;

  @include mq($from: desktop) {
    padding: $base-vertical-spacing--large $base-vertical-spacing--medium;
    text-align: left;
  }
}

@mixin pushDownBehindHeader() {

  .SiteHeader + & {
    padding-top: 100px;

    @include mq($from: desktop) {
      padding-top: 130px;
    }
  }
}
