.Logos {

  @extend %baseComponent;

  padding-top: $base-vertical-spacing;

  @include mq($from: desktop) {
    padding-top: $base-vertical-spacing--medium;
  }

  &-inner {

    @extend %maxWidth;
  }

  &-header {

    @include mq($from: desktop) {
      margin-bottom: $base-vertical-spacing--medium;
    }
  }

  &-subHeader {

    @extend %maxWidthNarrow;
    font-size: 1.4rem;
    font-weight: 600;
    text-align: center;

    @include mq($from: desktop) {
      font-size: 1.6rem;
      margin-bottom: $base-vertical-spacing--large;
    }
  }

  a {
    display: inline-block;
    // padding-right: 10px;
  }

  li:last-child {
    // margin-bottom: $base-vertical-spacing--medium;
  }

  &-ul {
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    @include mq($from: tablet) {
      justify-content: center;
    }

    @include mq($from: desktop) {
      // margin-bottom: $base-vertical-spacing;
      text-align: left;

      &:last-of-type {
        margin-bottom: $base-vertical-spacing--large;
        margin-top: $base-spacing;
      }
    }
  }

  &-li {
    display: flex;
    justify-content: center;
    width: 100%;

    @include mq($from: mobile) {
      width: 50%;
    }

    @include mq($from: tablet) {
      width: 33.3333333333%;
    }

    @include mq($from: desktop) {
      width: 25%;
    }
  }

  &-img {
    margin-bottom: $base-vertical-spacing--medium;
    max-width: 149px;
    width: 100%;
  }

  &-text {
    margin-bottom: $base-vertical-spacing--xlarge;
    text-align: center;
  }
}
