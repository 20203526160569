.SiteFooter {
  // background: $white;
  margin: auto;
  max-width: $max-inner-width;
  padding: $base-vertical-spacing--large $base-mobile-gutter;
  position: relative;

  @include mq($from: desktop) {
    margin: $base-vertical-spacing--large auto $base-vertical-spacing--xlarge;
    padding: 0;
  }

  img {
    height: 75px;
  }

  a {
    display: inline-block;
  }

  &-logo {
    margin-right: $base-mobile-gutter;
  }

  &-arrow {
    background: url("../img/scrollTopArrow.svg") center 16px no-repeat;
    border: 1px solid $black;
    bottom: $base-vertical-spacing--large;
    color: $white;
    cursor: pointer;
    font-size: 0.01px;
    height: 50px;
    padding: 0;
    position: absolute;
    right: $base-mobile-gutter;
    width: 50px;

    @include mq($from: desktop) {
      bottom: initial;
      top: 50%;
      transform: translateY(-50%);

      &:hover {
        background: url("../img/scrollTopArrowGreen.svg") center 16px no-repeat;
      }
    }
  }

  &-arrow img {
    height: 15px;
  }

  &-social {
    display: flex;
    justify-content: center;

    li {

      &:not(:last-child) {
        margin-right: 20px;
      }

      a {

        &.twitter {
          color: #1da1f2;
        }

        &.facebook {
          color: #3d5a98;
        }
      }
    }
  }
}
