.SiteHeader {
  background: white;
  box-shadow: 0 9px 21px -13px $light-grey;
  left: 0;
  margin: auto;
  padding: 3px $base-mobile-spacing;
  position: fixed;
  right: 0;
  top: 0;
  transition: width 0.2s;
  width: calc(100% - 50px);
  z-index: 100;

  @include mq($from: desktop) {
    height: 80px;
    max-width: $max-inner-width;
    padding: 0 30px;
    width: 75%;
  }

  a {
    display: inline-block;
  }

  &.is-expanded {
    padding-left: 25px;
    width: 100%;
  }

  &-logo {
    width: 125px;

    @include mq($from: desktop) {
      padding: 16px 0;
      width: 145px;
    }
  }
}

.SiteHeader--home {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .SiteHeader-nhs {
    width: 80px;

    @include mq($from: tablet) {
      width: auto;
    }
  }
}
