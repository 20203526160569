.CommonProblems {

  @extend %baseComponent;

  background: $medium-turquoise;
  position: relative;

  @include mq($from: desktop) {
    padding: $base-vertical-spacing--large 0 $base-vertical-spacing--xlarge;
  }

  &-inner {

    @extend %maxWidthNarrow;
  }

  &-header {
    margin-bottom: $base-vertical-spacing;
    position: relative;
    z-index: 0;

    @include mq($from: desktop) {
      margin-bottom: $base-vertical-spacing--large;
    }
  }

  p {
    position: relative;
    z-index: 2;
  }

  &.is-overlapped {
    padding-bottom: 33vw;

    @include mq($from: desktop) {
      padding-bottom: 180px;
    }
  }

  &-bgSvg {
    display: none;
  }

  @include mq($from: desktop) {

    &-bgSvg {
      display: block;
      position: absolute;
      z-index: 0;
    }

    &-bgSvg.squigle {
      left: 9%;
      top: 50px;
      width: 285px;
    }

    &-bgSvg.speech {
      left: 48%;
      top: 47%;
      width: 155px;
    }

    &-bgSvg.cloud {
      left: 75%;
      top: 20px;
      width: 155px;
    }
  }
}
