.Content {

  @extend %baseComponent;

  &-inner {

    @extend %maxWidthNarrow;
  }

  a {

    &:hover {
      color: $black;
      font-style: italic;
      text-shadow: #000 0 0.1px 0;
    }
  }
}
