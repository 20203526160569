.ExpandableList {
  background: $water-leaf;

  @extend %baseComponent;

  @include mq($from: desktop) {
    background: $water-leaf url("../img/WhiteSplotchesCircle.svg") 90% 5%/230px no-repeat;
  }

  &-inner {

    @extend %maxWidth;
  }

  &-header {

    @include mq($from: desktop) {
      margin-bottom: $base-vertical-spacing--large;
    }
  }

  &-expandBtn {
    background: url("../img/BulletArrowWhiteDown.svg") 45% 25% / 45px no-repeat;
    border: none;
    padding-top: 60px;
    transition: all 0.2s;
    width: 100px;

    @include mq($from: desktop) {
      display: none;
    }
  }

  &-li {

    @include mq($from: desktop) {
      display: inline-block;
      vertical-align: top;
      width: 32%;
    }
  }

  a {
    display: inline-block;
    padding: $base-spacing;

    @include mq($from: desktop) {
      margin-right: $base-mobile-gutter;
      padding: $base-vertical-spacing--small $base-spacing;

      &:hover {
        color: $black;
        font-style: italic;
        text-shadow: #000 0 0.1px 0;
      }
    }
  }
}

.ExpandableList.is-expanded {

  .ExpandableList-expandBtn {
    font-size: 0.1px;
    margin-bottom: 30px;
    transform: rotate(180deg);
  }
}
