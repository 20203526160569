.RelatedTopicList {

  @extend %baseComponent;

  background: $lemon;

  @include mq($from: desktop) {
    background: $lemon url("../img/WhiteSplotchesCircle.svg") 90% 20%/230px no-repeat;
  }

  &-inner {

    @extend %maxWidth;
  }

  a {
    display: inline-block;
    padding: $base-spacing;

    @include mq($from: desktop) {
      padding-left: 0;
    }
  }

  &-header {
    margin-bottom: $base-vertical-spacing--medium;

    @include mq($from: desktop) {
      font-size: 3.4rem;
    }
  }

  &-list,
  ul {
    margin-bottom: $base-vertical-spacing--medium;

    @include mq($from: desktop) {
      margin-bottom: 0;
    }
  }

  &-li,
  li {

    &:hover {
      font-style: italic;
      text-shadow: #000 0 0.1px 0;
    }
  }

  &-spotlightList {

    li:first-of-type {
      margin-bottom: $base-vertical-spacing;
    }
  }

  &-list,
  ul,
  &-spotlightList {

    @include mq($from: desktop) {
      columns: 2;
    }
  }

  &-spotlight {
    position: relative;

    &:hover h6 {
      font-style: italic;
      font-weight: 600;
    }

    img,
    h6 {
      margin-bottom: $base-vertical-spacing--small;
    }

    h6 {
      font-size: 1.8rem;
    }

    img {

      @include mq($from: desktop) {
        left: -40px;
        position: absolute;
        top: 12px;
      }
    }
  }
}
