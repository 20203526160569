.Search {
  margin: 0 auto $base-vertical-spacing--large;
  max-width: 500px;
  position: relative;

  @include mq($from: desktop) {
    left: 0;
    margin: auto;
    max-width: none;
    position: absolute;
    right: 0;
    top: 60%;
    width: 825px;
  }

  &::before {
    background: url("../img/MagnifyGlass.svg") 50% 50% / 25px no-repeat;
    content: "";
    display: block;
    height: 25px;
    left: 7px;
    position: absolute;
    top: 8px;
    width: 25px;

    @include mq($from: desktop) {
      background-size: 35px;
      height: 36px;
      left: 10px;
      top: 20px;
      width: 35px;
    }
  }

  &-input {
    border: none;
    border-radius: 5px;
    height: 50px;
    padding: 0 95px 0 40px;
    width: calc(100% - 135px);

    @include mq($from: desktop) {
      height: 75px;
      padding: 0 170px 0 60px;
      width: 595px;
    }

    &.is-expanded {
      border-radius: 5px 5px 0 0;
    }
  }

  &-button {
    background: $bright-green;
    color: $dark-grey;
    height: 35px;
    position: absolute;
    right: 7px;
    top: 8px;
    width: 85px;

    @include mq($from: desktop) {
      height: 55px;
      right: 10px;
      top: 10px;
      width: 160px;

      &:hover {
        background: $lima;
        cursor: pointer;
      }
    }
  }

  &-suggestions {
    background: $white;
    box-shadow: 0 9px 21px -13px $light-grey;
    display: none;
    position: absolute;
    width: calc(100% - 40px);
    z-index: 1;

    @include mq($from: desktop) {
      display: block;
      width: 100%;
    }
  }

  &-suggestionItem {
    border-top: 1px solid $light-grey;

    a {
      display: block;
      padding: 20px 0 20px 60px;

      &:hover {
        background: $white-smoke;
      }
    }

    .hl {
      color: $lima;
      font-weight: 600;
    }
  }
}

.reduced .Search {
  margin-top: 30px;

  @include mq($from: desktop) {
    margin: 0 auto;
    top: 50%;
  }
}
