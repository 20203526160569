.Cta {

  @extend %baseComponent;

  &-inner {

    @extend %maxWidth;
    text-align: center;
  }

  &-heading {

    @include mq(desktop) {
      font-size: 3.5rem;
      margin-top: 2rem;
    }
  }

  &-cite,
  &-citation {
    color: $lima;
    display: block;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: bold;
    margin-bottom: $base-vertical-spacing--medium;
  }

  &-link {
    background-color: $white;
    border-radius: 4px;
    display: inline-block;
    font-family: "Cocon", sans-serif;
    font-size: 2rem;
    padding: 10px 30px;

    &:hover {
      background-color: darken($white, 5%);
    }

    @include mq(desktop) {
      font-size: 3rem;
      padding: 17px 45px;
    }
  }
}

.Cta--default {

  .Cta-heading {

    @include mq(desktop) {
      font-size: 4rem;
      margin-top: 4rem;
    }
  }

  .Cta-text {

    @extend %maxWidthNarrow;
    margin-bottom: $base-vertical-spacing--medium;

    @include mq(tablet) {
      font-size: 1.9rem;
    }
  }

  @include mq($from: desktop) {
    padding-bottom: $base-vertical-spacing--xlarge;
  }
}

.Cta--green {
  background-color: $bright-green;
}

.Cta--yellow {
  background-color: $lemon;
}
