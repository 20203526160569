.TopicList {
  padding: $base-vertical-spacing $base-mobile-gutter;
  text-align: center;

  @include mq($from: desktop) {
    text-align: left;
  }

  &-inner {

    @extend %maxWidth;
  }

  &-header {
    margin-bottom: $base-vertical-spacing--medium;

    @include mq($from: desktop) {
      margin-bottom: $base-vertical-spacing--large;
    }
  }

  &-ul {

    @include mq($from: desktop) {
      display: flex;
      flex-wrap: wrap;
    }

    &.bright-green {

      li {
        border-top: 2px solid $bright-green;
      }
    }

    &.downy {

      li {
        border-top: 2px solid $downy;
      }
    }

    &.bright-green,
    &.downy {

      li {

        @include mq($from: desktop) {
          border-top: none;
        }
      }
    }
  }

  &-img {
    display: none;

    @include mq($from: desktop) {
      display: block;
      margin-bottom: $base-vertical-spacing;
    }
  }

  &-li {
    background: $white-smoke;
    margin: 0 auto $base-mobile-spacing;

    @include mq($from: desktop) {
      background: transparent;
      margin: 0 auto $base-vertical-spacing;
      // margin-bottom: $base-vertical-spacing;
      width: 23%;

      &:hover .TopicList-topic {
        color: $black;
        font-style: italic;
        text-shadow: #000 0 0.1px 0;
      }
    }

    a {
      display: block;
      padding: 27px 0;

      @include mq($from: desktop) {
        padding: 0;
      }
    }
  }

  &-topic {
    font-weight: 600;

    @include mq($from: desktop) {
      font-size: 1.8rem;
      height: 6rem;
      line-height: 3rem;
    }
  }

  &.greySplotches {

    @include mq($from: desktop) {
      background: url("../img/GreySplotches.svg") 9% 79%/200px no-repeat;
    }
  }
}
