.Video {
  padding: 0 $base-mobile-gutter;
  text-align: center;

  @include mq($from: desktop) {
    margin-bottom: 0;
    padding: 0 $inner-width-padding;
    text-align: left;
  }

  &-inner {

    @extend %maxWidthNarrow;
  }

  // Offset content to overlap preceding element

  &-inner,
  &-iFrame,
  &-header,
  &-paragraph {
    position: relative;
    top: -20vw;

    @include mq($from: desktop) {
      top: -100px;
    }
  }

  &.violetRed {
    background: $violet-red;
  }

  &.white {
    background: $white;
  }

  &.tickleMePink {
    background: $tickle-me-pink;
  }

  &-iFrame {
    border: 0;
  }

  &-header {

    @include mq($from: desktop) {
      margin: $base-vertical-spacing--large auto $base-vertical-spacing--medium;
      padding-top: $base-vertical-spacing;
    }
  }

  &-paragraph {
    padding-bottom: $base-vertical-spacing--xlarge;

    @include mq($from: desktop) {
      padding: 0;
    }
  }
}
