.SessionTopicList {

  @extend %baseComponent;

  @include mq($from: desktop) {
    background: $white url("../img/RainingPotato.svg") 75% 75%/25% no-repeat;
  }

  &-inner {

    @extend %maxWidth;
  }

  &-header {
    margin-bottom: $base-vertical-spacing--medium;

    @include mq($from: desktop) {
      margin-bottom: $base-vertical-spacing--large;
    }
  }

  &-ul {

    @include mq($from: desktop) {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      border-top: 2px solid $bright-green;

      @include mq($from: desktop) {
        border-top: none;

        &:hover {
          font-style: italic;
          text-shadow: #000 0 0.1px 0;
        }
      }
    }
  }

  &-img {
    display: none;

    @include mq($from: desktop) {
      display: block;
      margin-bottom: $base-vertical-spacing;
      width: 100%;
    }
  }

  &-li {
    background: $white-smoke;
    margin-bottom: $base-mobile-spacing;

    @include mq($from: desktop) {
      background: transparent;
      margin: 0 $base-vertical-spacing 30px 0;
      width: 22%;
    }

    a {
      display: block;
      padding: 27px 0;

      @include mq($from: desktop) {
        padding: 0;
      }
    }

    h6 {
      margin-bottom: 0;
    }
  }

  &-topic {

    @include mq($from: desktop) {
      font-size: 1.8rem;
      height: 6rem;
      line-height: 3rem;
    }
  }

  &-li--sub {

    @include mq($from: desktop) {
      background: $bright-green;
      display: flex;

      a {
        margin: auto 0;
        padding: 30px $base-vertical-spacing;
        width: 100%;
      }

      h6 {
        height: initial;
        line-height: normal;
      }
    }
  }

  &-li,
  &-li--sub {

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}
