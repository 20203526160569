.FooterNav {
  margin-bottom: $base-vertical-spacing--large;
  text-align: center;

  li {
    display: inline-block;
  }

  a {
    color: $lightened-grey;
    font-size: 1.3rem;
    padding: $base-mobile-spacing;

    &:hover {
      border-bottom: 1px solid $bright-green;
      box-sizing: border-box;
      color: $dark-grey;
      height: 30px;
      text-shadow: #000 0 0.1px 0;
    }
  }

  @include mq($from: desktop) {
    bottom: $base-spacing;
    left: 225px;
    margin-bottom: 0;
    position: absolute;
  }
}
