// Shades

$white: #fff;
$white-smoke: #f6f4f6;
$white-smoke-bg: #f9f9f9;
$white-smoke-pagination: #e9e9e9;
$light-grey: #d8d8d8;
$dark-grey: #4a4a4a;
$lightened-grey: lighten($dark-grey, 20);
$black: black;

// Colours
// Names from http://www.color-blindness.com/color-name-hue/

$bright-green: #cedc00;
$lima: #78be20;
$downy: #6ccfcf;
$water-leaf: #aae7d4;
$violet-red: #f23c64;
$tickle-me-pink: #ff8eaa;
$medium-turquoise: #3fd2d0;
$mauve: #c5a9ff;
$lemon: #f8e71c;

// Spacing

$base-mobile-spacing: 8px;
$base-spacing: 9px;
$base-mobile-gutter: 17px;
$base-vertical-spacing--small: 12px;
$base-vertical-spacing: 20px;
$base-vertical-spacing--medium: 35px;
$base-vertical-spacing--large: 50px;
$base-vertical-spacing--xlarge: 80px;

// Layout

$max-inner-width: 1000px;
$max-inner-width--narrow: 825px;
$inner-width-padding: 20%;
