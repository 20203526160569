.SpotlightThree {
  background-color: $bright-green;
  margin-bottom: $base-vertical-spacing;
  padding: $base-vertical-spacing--large $base-mobile-gutter $base-vertical-spacing--medium;
  position: relative;
  text-align: center;

  @include mq($from: desktop) {
    margin-bottom: 207px;
    text-align: left;
  }

  &-inner {

    @extend %maxWidth;
  }

  &-header {
    margin-bottom: $base-vertical-spacing;
    position: relative;
    z-index: 2;
  }

  &-subheader {
    margin-bottom: $base-vertical-spacing--large;
    position: relative;
    z-index: 2;
  }

  &-figureContainer {

    @include mq($from: desktop) {
      display: flex;
      margin-bottom: -207px;

      a {
        margin-right: $base-mobile-gutter;
        width: 25%;

        &:first-of-type {
          width: 50%;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  &-figure {
    margin-bottom: $base-vertical-spacing;
    position: relative;
    z-index: 2;

    &:hover .SpotlightThree-caption {
      background: $medium-turquoise;
      font-style: italic;
      text-shadow: #000 0 0.1px 0;
    }
  }

  &-imageDiv {
    display: none;
    height: 140px;
    position: relative;

    @include mq($from: desktop) {
      display: block;
      height: 310px;
    }

    > img {
      display: block;
      font-family: "object-fit: cover;";
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }
  }

  &-caption {
    background: $lima;
    font-weight: bold;
    padding: $base-vertical-spacing--medium $base-spacing;
    transition: background 0.2s;

    @include mq($from: desktop) {
      font-size: 1.8rem;
      height: 118px;
      line-height: 3rem;
      padding: $base-mobile-gutter;
    }
  }

  &-bgSvg {
    display: none;
  }

  @include mq($from: desktop) {

    &-bgSvg {
      display: block;
      position: absolute;
      z-index: 0;
    }

    &-bgSvg.spiral {
      left: 15px;
      top: 50px;
    }

    &-bgSvg.whiteSplotches {
      left: 45%;
      top: 15%;
    }

    &-bgSvg.cross {
      left: 65%;
      top: 20px;
    }

    &-bgSvg.circlesLine {
      left: 80%;
      top: 20%;
    }
  }
}
