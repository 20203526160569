.AboutUs {

  @extend %baseComponent;

  background: $mauve;

  @include mq($from: desktop) {
    background: $mauve url("../img/MidGreySplotches.svg") repeat;
    padding: $base-vertical-spacing--large 0 $base-vertical-spacing--xlarge;
  }

  &-inner {

    @extend %maxWidthNarrow;
  }

  &-header {
    margin-bottom: $base-vertical-spacing;
    z-index: 0;

    @include mq($from: desktop) {
      margin-bottom: $base-vertical-spacing--large;
    }
  }

  &-intro {
    margin-bottom: $base-vertical-spacing--medium;
  }

  &-listHeader {
    font-weight: bold;
    margin-bottom: $base-vertical-spacing;
  }

  &-list,
  ul {
    padding: 0 25px;

    @include mq($from: desktop) {
      padding: 0 0 0 40px;
    }

    li {
      margin-bottom: $base-vertical-spacing;
    }

    li {
      position: relative;

      &::before {
        content: url("../img/BulletArrowWhite.svg");
        height: 30px;
        left: -30px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
      }
    }
  }

  @include pushDownBehindHeader;
}
