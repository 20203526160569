.HeaderNav {

  &-expandContainer {
    align-items: center;
    color: $dark-grey;
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: $base-mobile-spacing;
    top: 8px;
    width: 70px;

    p {
      font-size: 1rem;
    }

    @include mq($from: desktop) {
      display: none;
    }
  }

  &-expand {
    background: url("../img/hamburger-menu-mock.svg") center / 16px no-repeat;
    border: 2px solid $light-grey;
    border-radius: 50%;
    color: white;
    font-size: 0.1px;
    height: 32px;
    width: 32px;

    &:focus {
      outline: 0;
    }
  }

  &-list {

    li {
      display: inline-block;
    }

    @include mq($until: desktop) {
      background: $bright-green;
      height: 100%;
      left: 0;
      position: fixed;
      text-align: center;
      top: 70%;
      width: 100%;

      a {
        font-size: 1.3rem;
        padding: 10px;
      }
    }

    @include mq($from: desktop) {
      position: absolute;
      right: 30px;
      top: 5px;

      a {
        color: $lightened-grey;
        font-size: 1.2rem;
        padding: $base-mobile-spacing;

        &:hover {
          color: $dark-grey;
          text-decoration: underline;
          text-decoration-color: $bright-green;
          text-shadow: #000 0 0.1px 0;
        }
      }
    }
  }

  &-activeLink {

    a {
      color: $dark-grey;
      text-decoration: underline;
      text-decoration-color: $bright-green;
      text-shadow: #000 0 0.1px 0;
    }
  }
}

.SiteHeader.is-expanded .HeaderNav-expandContainer {
  position: fixed;
  right: 25px;
}

.SiteHeader.is-expanded .HeaderNav-expand {
  background: url("../img/BurgerMenuClose.svg") center / 36px no-repeat;
}
