.ConcernList {

  font-family: "Cocon", sans-serif;

  @include mq($from: desktop) {

    a:hover {
      border-bottom: 2px solid $bright-green;
      box-sizing: border-box;
      height: 27px;
    }
  }

  @include mq($from: wide) {

    a:hover {
      height: 34px;
    }
  }
}

.HeaderNav .ConcernList {

  @include mq($until: desktop) {
    background: $bright-green;
    height: 100%;
    left: 0;
    padding-top: 22%;
    position: fixed;
    text-align: center;
    top: 49px;
    width: 100%;

    a {
      display: block;
      font-size: 1.8rem;
      padding: $base-vertical-spacing;
    }

    li {
      position: relative;
    }

    li:not(:last-of-type)::after {
      border-bottom: 2px solid $white;
      bottom: 0;
      content: "";
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      width: 25px;
    }
  }

  @include mq($from: desktop) {
    bottom: 10px;
    display: block;
    position: absolute;
    right: 30px;

    li {
      display: inline-block;
    }

    a {
      color: $dark-grey;
      font-size: 1.5rem;
      padding: 5px;

      @include mq($from: wide) {
        font-size: 1.7rem;
        padding: 8px;
      }
    }
  }
}

.PrimaryHero .ConcernList,
.SiteFooter .ConcernList {
  font-size: 1.4rem;
  text-align: center;

  li {
    margin-bottom: 13px;
  }

  a {
    padding: 5px;
  }
}

.PrimaryHero .ConcernList {
  margin-bottom: $base-vertical-spacing--large;

  a {
    color: $white;
  }

  @include mq($from: desktop) {
    display: none;
  }
}

.SiteFooter .ConcernList {

  margin-bottom: $base-vertical-spacing--large;

  a {
    color: $dark-grey;
  }

  @include mq($from: desktop) {
    left: 225px;
    margin: 0;
    position: absolute;
    top: 0;

    li {
      display: inline-block;
    }

    a {
      padding: $base-spacing;
    }
  }
}
