.SearchResults {

  @extend %baseComponent;

  background: $white-smoke-bg;
  padding-top: 100px;

  @include mq($from: desktop) {
    padding-top: 150px;
  }

  &-inner {

    @extend %maxWidthNarrow;
  }

  &-pagination {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: $base-vertical-spacing--large;

    @include mq($from: desktop) {
      margin-bottom: $base-vertical-spacing--xlarge;
    }

    .Pagination-button {
      border: transparent;
      border-radius: 50%;
      color: $white-smoke-bg;
      cursor: pointer;
      font-size: 0.1px;
      height: 33px;
      margin: 0;
      width: 33px;
    }

    .Pagination-button:first-child {
      background: $bright-green url("../img/BulletArrowWhite.svg") 58% 50%/13px no-repeat;
      margin-right: $base-mobile-gutter;
      transform: rotate(180deg);
    }

    .Pagination-button:last-child {
      background: $bright-green url("../img/BulletArrowWhite.svg") 58% 50%/13px no-repeat;
      margin-left: $base-mobile-gutter;
    }
  }

  &-pages {
    margin: 0;

    li {
      display: inline-block;

      &.isCurrent {
        align-items: center;
        background: $white-smoke-pagination;
        border-radius: 50%;
        display: inline-flex;
        height: 33px;
        justify-content: center;
        padding: 0;
        width: 33px;
      }
    }

    a {
      font-size: 1.3rem;
      padding: 7px;
    }
  }

  &-result {
    display: block;
    margin-bottom: $base-vertical-spacing--xlarge;
    position: relative;

    &:not(:last-of-type)::after {
      border-bottom: 1px solid $light-grey;
      bottom: -$base-vertical-spacing--medium;
      content: "";
      left: 0;
      position: absolute;
      width: 100%;
    }

    @include mq($from: desktop) {
      display: flex;

      div {
        margin: 0;
      }
    }

    h6 {
      margin: 0;
    }

    a {
      display: block;

      &:hover + div h3 {
        font-style: italic;
      }
    }
  }

  &-header {

    a,
    & {
      font-size: 1.8rem;
      padding: 4px 0;

      @include mq($from: desktop) {
        font-size: 3rem;
        margin-bottom: $base-vertical-spacing;

        &:hover {
          font-style: italic;
        }
      }
    }
  }

  &-img {
    margin-bottom: $base-vertical-spacing;

    @include mq($from: desktop) {
      margin-right: $base-vertical-spacing;
    }
  }

  &-description {
    margin-bottom: $base-vertical-spacing--small;
  }
}
