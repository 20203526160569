.CookieNotification {
  background: $white;
  bottom: 0;
  box-shadow: 0 -9px 21px -13px $light-grey;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 2;

  @include mq($from: tablet) {
    padding: 0 $base-spacing;
  }

  &-container {
    padding: $base-spacing 60px $base-spacing $base-spacing;
  }

  &-message,
  &-header,
  a {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  a {
    text-decoration: underline;
  }

  &-header {
    margin: 0;
  }

  &-close {
    background: url("../img/BurgerMenuClose.svg") 100% 50% / 36px no-repeat;
    border: none;
    color: $white;
    cursor: pointer;
    font-size: 0.1px;
    height: 100%;
    position: absolute;
    right: 10px;
    top: 0;
    width: 40px;

    @include mq($from: tablet) {
      background: url("../img/BurgerMenuClose.svg") 50% 50% / 25px no-repeat;
      right: 20px;
    }
  }
}
