@font-face {
  font-family: "Cocon";
  src: url("../fonts/b22f6857-819c-40d8-bac7-dad159e738f4.eot")  /* IE9 Compat Modes */;
  src:
    url("../fonts/c43d2d87-072b-42bf-a378-51a1f808b2f1.woff2") format("woff2"),
    url("../fonts/9815300f-1d30-4d22-bcaa-282cac0de5c0.woff") format("woff"),
    url("../fonts/7c0f701f-547b-4655-b689-ed670094ff72") format("truetype");
}

html {
  font-size: 62.5%;
}

body {
  background-color: #f9f9fb;
  color: $dark-grey;
  font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4 {
  font-family: "Cocon", sans-serif;
  font-weight: normal;
  margin-top: 0;
}

h5,
h6 {
  font-weight: normal;
  margin-top: 0;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.7rem;

  @include mq($from: desktop) {
    font-size: 4rem;
  }
}

h3 {
  font-size: 1.9rem;
}

h4 {
  font-size: 1.8rem;
}

h5 {
  font-size: 1.7rem;
  margin-bottom: 0;
}

h6 {
  font-size: 1.6rem;
}

p,
a,
input,
button,
figcaption,
li,
q,
blockquote {
  font-size: 1.6rem;
}

button,
figcaption,
a,
q,
blockquote {
  color: $dark-grey;
}

blockquote,
p,
figure {
  margin: 0;
}

button {
  font-family: "Cocon", sans-serif;
}

p,
h6 {
  line-height: 2.7rem;

  @include mq($from: desktop) {
    line-height: 3rem;
  }
}

a {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

hr {
  border: 0;
  border-top: 1px solid #979797;
}
