.Accordion {
  margin-top: $base-vertical-spacing--medium;

  @extend %baseComponent;
  @extend %maxWidth;

  &-button {
    background-color: $white-smoke;
    display: block;
    padding: 2rem 2.5rem;
    padding-right: calc(2.5rem + 45px);
    position: relative;
    text-align: left;

    @include mq($from: tablet) {
      padding: 3.5rem 5rem;
      padding-right: calc(5rem + 45px);
    }

    h2 {
      margin-bottom: 0;
    }

    &:hover {

      h2 {
        text-decoration: underline;
      }
    }

    &::after {
      background-image: url("../img/down-arrow.png");
      background-size: 45px 25px;
      content: "";
      display: block;
      height: 25px;
      margin-top: -13px;
      position: absolute;
      right: 2.5rem;
      top: 50%;
      width: 45px;

      @include mq($from: tablet) {
        right: 5rem;
      }
    }
  }

  &-content {
    display: none;
    padding: 1.5rem 2.5rem;
    text-align: left;

    @include mq($from: tablet) {
      font-size: 1.9rem;
      line-height: 3.6rem;
      padding: 3.5rem 5rem;
    }

    ul,
    ol {
      list-style: disc;
    }
  }

  &--is-open {

    .Accordion-button::after {
      transform: scaleY(-1);
    }

    .Accordion-content {
      display: block;
    }
  }
}
